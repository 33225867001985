module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"divgra","accessToken":"","releaseID":"","prismicToolbar":true,"schemas":{"blogHome":{"Main":{"headline":{"type":"StructuredText","config":{"single":"heading1","label":"Headline","placeholder":"Main title..."}},"description":{"type":"StructuredText","config":{"multi":"heading2","label":"Description","placeholder":"Sub-title..."}},"image":{"type":"Image","config":{"constraint":{"width":140,"height":140},"thumbnails":[],"label":"Image"}}}},"post":{"Blog Post":{"uid":{"type":"UID","config":{"label":"UID","placeholder":"unique-identifier-for-blog-post-url"}},"title":{"type":"StructuredText","config":{"single":"heading1","label":"Title","placeholder":"Blog Post Title..."}},"date":{"type":"Date","config":{"label":"Date"}},"body":{"type":"Slices","fieldset":"Slice zone","config":{"labels":{"image_with_caption":[{"name":"emphasized","display":"Emphasized"},{"name":"image-full-width","display":"Full"}]},"choices":{"text":{"type":"Slice","fieldset":"Text","description":"A rich text section","icon":"text_fields","non-repeat":{"text":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading2, heading3, strong, em, hyperlink, embed, list-item, o-list-item, o-list-item","allowTargetBlank":true,"label":"Text","placeholder":"Post text..."}}},"repeat":{}},"quote":{"type":"Slice","fieldset":"Quote","description":"A quote section","icon":"format_quote","non-repeat":{"quote":{"type":"StructuredText","config":{"single":"paragraph","label":"Quote","placeholder":"Post Quote..."}}},"repeat":{}},"image_with_caption":{"type":"Slice","fieldset":"Image with Caption","description":"An image with an optional caption","icon":"image","non-repeat":{"image":{"type":"Image","config":{"constraint":{"width":1200},"thumbnails":[],"label":"Image"}},"caption":{"type":"StructuredText","config":{"single":"heading3","label":"Caption","placeholder":"Image Caption..."}}},"repeat":{}}}}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"42b93fed6f8e355f95918d74c1ec11a0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
